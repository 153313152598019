<script setup lang="ts">
import { computed, ref, inject, onMounted, watch, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { useRoute, useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import capitalize from 'lodash/capitalize';
import pullAll from 'lodash/pullAll';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import {
  DataPointRequestStatusEnum,
  DataPointTypeRefreshIntervalEnum,
  DataPointTypeValueUnitEnum,
  type DataPointTypeOverrideInput,
  type DeleteDataPointRequestsInput,
  UserRole, type DataPointTypeEmissionFactor, ValueDataTypeEnum,
} from '@/__generated__/types';
import useDeleteDataPointRequestDelegationMutation from '@/api/mutations/DataPointRequest/deleteDataPointRequestDelegation.mutation';
import useAddDataPointTypeOverrideMutation from '@/api/mutations/EntityLocation/addDataPointTypeOverride.mutation';
import useDeleteDataPointRequestsMutation from '@/api/mutations/DataPointRequest/deleteDataPointRequests.mutation';
import useGenerateDataPointRequestsForPastDatesMutation from '@/api/mutations/EntityLocation/generateDataPointRequestsForPastDates.mutation';
import MlStandardsBadges from '@/components/molecules/MlStandardsBadges.vue';

import { apolloClient } from '@/api/apollo/client';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import TmCustomDataPointTypeModal from '@/components/pages/PgProjects/PgProject/TmCustomDataPointTypeModal/TmCustomDataPointTypeModal.vue';
import MlCustomBadges from '@/components/molecules/MlCustomBadges.vue';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import { getUserName } from '@/utils/helpers/getUserName';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import useResetDataPointRequestMutation from '@/api/mutations/DataPointRequest/resetDataPointRequest.mutation';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import type { Project, TQuestionDataTableItem, TDataPointTypeOverride, EntityTag } from '../../../types';
import OgEditQuestionModal, { type TEditQuestionPayload } from './OgEditQuestionModal.vue';
import OgQuestionContent from './OgQuestionContent.vue';
import MlDelegateQuestionModal from './MlDelegateQuestionModal.vue';
import MlRemoveValueModal from './MlRemoveValueModal.vue';
import MlCustomTagsPopover from './MlCustomTagsPopover.vue';
import MlArchiveModal from './MlArchiveModal.vue';

const emit = defineEmits<{ updateActiveQuestion: [item: TQuestionDataTableItem | undefined] }>();
const props = defineProps<{
  itemGroup: TQuestionDataTableItem[];
  index: number;
  project: Project;
  isChilds?: boolean;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { isAdminOrSuperAdmin, currentUser } = useCurrentUser();

const isDelegationAssignee = computed(
  () => !!props.itemGroup[0].dpr.delegations?.find((delegation) => delegation?.user._id === currentUser.value?._id));

const questionContent = ref<InstanceType<typeof OgQuestionContent> | null>(null);
const activeChilds = computed(() => questionContent.value?.activeChilds ?? []);

const itemGroupDprIds = computed(() => props.itemGroup.map((item) => item.dpr._id));
const questionButton = ref<HTMLElement>();
const activeCollapses = inject('activeCollapses', reactive<Record<string, boolean>>({}));
const flattenDptIds = computed(() => [
  props.itemGroup[0].dpr.dataPointType._id,
  ...props.itemGroup.flatMap((item) => item.dpr.childs?.map((child) => child.dataPointType._id) ?? []),
  ...activeChilds.value.flatMap((item) => item.dpr.childs?.map((subchild) => subchild.dataPointType._id) ?? []),
]);
const flattenDprIds = computed(() => [
  ...itemGroupDprIds.value,
  ...props.itemGroup.flatMap((item) => item.dpr.childs?.map((child) => child._id) ?? []),
  ...activeChilds.value.flatMap((item) => item.dpr.childs?.map((subchild) => subchild._id) ?? []),
]);

const isDptSelected = computed(() => flattenDptIds.value.includes(route.query.questiontype?.toString() ?? ''));
const isDprSelected = computed(() => flattenDprIds.value.includes(route.query.questionid?.toString() ?? ''));
const isSelected = computed(() => isDprSelected.value && isDptSelected.value);
const isMainSelected = computed(() => activeCollapses[props.itemGroup[0].dpr._id]
  && !activeChilds.value.find((child) => activeCollapses[child.dpr._id]));

const dataPointTypeOverrides = inject('dataPointTypeOverrides', computed(() => [] as TDataPointTypeOverride[]));
const entityTags = inject('entityTags', computed(() => [] as EntityTag[]));

const override = computed(
  () => dataPointTypeOverrides.value?.find((_override) => _override.datapointtype._id === props.itemGroup[0].dpr.dataPointType._id));

watch(isSelected, () => {
  activeCollapses[props.itemGroup[0].dpr._id] = isSelected.value;
  if (activeCollapses[props.itemGroup[0].dpr._id]) {
    setTimeout(() => {
      if (!navigator.userAgent.toLowerCase().includes('firefox')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (questionButton.value as any)?.scrollIntoViewIfNeeded(false);
      // } else {
        // questionButton.value?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 150);
  }
});

const slideToCurrent = () => {
  if (isSelected.value) {
    activeCollapses[props.itemGroup[0].dpr._id] = isSelected.value;
    questionButton.value?.scrollIntoView({ behavior: 'smooth' });
  }
};

onMounted(() => {
  slideToCurrent();
});

const {
  mutate: resetDataPointRequest,
  error: resetDataPointRequestError,
} = useResetDataPointRequestMutation();

const {
  mutate: deleteDataPointRequestDelegation,
  loading: deleteDataPointRequestDelegationLoading,
  error: deleteDataPointRequestDelegationError,
} = useDeleteDataPointRequestDelegationMutation();

const {
  mutate: addDataPointTypeOverride,
  loading: addDataPointTypeOverrideLoading,
  error: addDataPointTypeOverrideError,
} = useAddDataPointTypeOverrideMutation();

const {
  mutate: deleteDataPointRequests,
  loading: deleteDataPointRequestsLoading,
  error: deleteDataPointRequestsError,
} = useDeleteDataPointRequestsMutation();

const {
  mutate: generateDataPointRequestsForPastDates,
  loading: generateDataPointRequestsForPastDatesLoading,
  error: generateDataPointRequestsForPastDatesError,
} = useGenerateDataPointRequestsForPastDatesMutation();

const historicDprs = computed(() => props.itemGroup.filter((itemGroup) => itemGroup.dpr.isHistoric));
const itemGroupSorted = computed(
  () => props.itemGroup
    .slice()
    .sort(
      (a, b) => new Date(a.dpr.from).getTime() - new Date(b.dpr.from).getTime(),
    ));

function toggleCollapse(dprId: string) {
  if (activeCollapses[dprId] && flattenDptIds.value.includes(props.itemGroup[0].dpr.dataPointType._id)) {
    activeCollapses[dprId] = false;
  } else {
    router.push({
      query: {
        category: route.query.category,
        subcategory: route.query.subcategory,
        questiontype: props.itemGroup[0].dpr.dataPointType._id,
        questionid: dprId,
      } });
    emit('updateActiveQuestion', props.itemGroup[0]);
    activeCollapses[dprId] = true;
  }
}

const isAccepted = computed(() => props.itemGroup[0].dpr.status === DataPointRequestStatusEnum.ACCEPTED);
const isPending = computed(() => props.itemGroup[0].dpr.status === DataPointRequestStatusEnum.PENDING);
const isCustom = computed(
  () => (props.itemGroup[0].dpr.dataPointType.location && props.itemGroup[0].dpr.dataPointType.location?._id === props.project._id)
  || (props.itemGroup[0].dpr.dataPointType.entity && props.itemGroup[0].dpr.dataPointType.entity?._id === currentUser.value?.entity._id)
  || (props.itemGroup[0].dpr.dataPointType.organization
      && props.itemGroup[0].dpr.dataPointType.organization._id === currentUser.value?.entity?.organization?._id),
);

const getTaskCount = (items: TQuestionDataTableItem[]) => items.reduce((count, { dpr }) => count + (dpr.childs?.length || 0), 0);

const isArchiveModalRevealed = ref(false);
const isRemoveValueModalRevealed = ref(false);
const isDelegateQuestionModalRevealed = ref(false);
const isEditQuestionModalRevealed = ref(false);
const isCustomDataPointTypeModalRevealed = ref(false);
const editQuestionItem = ref<TQuestionDataTableItem | null>(null);

function openRemoveValueModal() {
  isRemoveValueModalRevealed.value = true;
}
function openDelegateQuestionModal() {
  isDelegateQuestionModalRevealed.value = true;
}

function openEditQuestionModal() {
  isEditQuestionModalRevealed.value = true;
  const [item] = props.itemGroup;
  editQuestionItem.value = item;
}

function openCustomDataPointTypeModal() {
  isCustomDataPointTypeModalRevealed.value = true;
}

function resetModalData() {
  isEditQuestionModalRevealed.value = false;
  editQuestionItem.value = null;
}

const isEditable = computed(
  // isEditable if dpr is parent or if dpr is child with valueUnit
  () => !props.isChilds || (props.isChilds && props.itemGroup[0].dpr.dataPointType.valueUnit),
);

async function handleConfirm(payload: TEditQuestionPayload) {
  const {
    dataPointTypeId,
    refreshInterval,
    valueUnit,
    pastDates,
    deleteDates,
    emissionFactorValue,
    emissionFactorYear,
    isEmissionFactorGlobal,
    isCustomUnitValueGlobal,
    isSplit,
    isDelegated,
    isRemovingDelegation,
    customConversionFactor,
  } = payload;

  const existingOverride = dataPointTypeOverrides.value.find((_override) => _override.datapointtype._id === dataPointTypeId);
  try {
    let dataPointTypeOverrideInput = { dataPointTypeId, isDelegated } as DataPointTypeOverrideInput;
    if (refreshInterval !== (existingOverride?.refreshInterval ?? props.itemGroup[0].dpr.dataPointType.refreshInterval)) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput,
        refreshInterval: refreshInterval as DataPointTypeRefreshIntervalEnum,
      };
    }

    if (valueUnit) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput,
        valueUnit: valueUnit as DataPointTypeValueUnitEnum,
      };
    }

    if (isCustomUnitValueGlobal) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput,
        isCustomUnitValueGlobal,
      };
    }

    if (customConversionFactor != null) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput,
        customConversionFactor,
      };
    }

    if (isSplit != null) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput, isSplit,
      };
    }

    if (
      (emissionFactorYear
      && emissionFactorValue
        !== (existingOverride?.emissionFactors?.find((ef: Partial<DataPointTypeEmissionFactor>) => ef.year === emissionFactorYear)?.factor
        ?? props.itemGroup[0].dpr.dataPointType.emissionFactors.find((ef) => ef.year === emissionFactorYear)?.factor))
      || isEmissionFactorGlobal
    ) {
      dataPointTypeOverrideInput = {
        ...dataPointTypeOverrideInput, emissionFactorValue, emissionFactorYear, isEmissionFactorGlobal,
      };
    }

    if (isDelegated && isRemovingDelegation) {
      const results = await Promise.all(props.itemGroup.map(
        (item) => deleteDataPointRequestDelegation({ dataPointRequestId: item.dpr._id })));
      dataPointTypeOverrideInput.isDelegated = results.every(
        (result) => !!result?.data?.deleteDataPointRequestDelegation.delegations?.length);
    }

    if (
      dataPointTypeOverrideInput.refreshInterval
      || dataPointTypeOverrideInput.valueUnit
      || dataPointTypeOverrideInput.customConversionFactor
      || (dataPointTypeOverrideInput.emissionFactorValue
        && dataPointTypeOverrideInput.emissionFactorYear)
        || dataPointTypeOverrideInput.isSplit != null
    ) {
      await addDataPointTypeOverride({
        dataPointTypeOverrideInput,
        entityLocationInput: {
          _id: props.project._id,
        },
      });
    }
    if (deleteDates.length) {
      const dataPointRequestIds = historicDprs.value
        .filter((itemGroup) => deleteDates.some((deleteDate) => deleteDate === new Date(itemGroup.dpr.from).getFullYear()))
        .map((_item) => _item.dpr._id);

      const deleteDataPointRequestsInput: DeleteDataPointRequestsInput = { dataPointRequestIds };
      await deleteDataPointRequests({ deleteDataPointRequestsInput });
    }
    if (pastDates.length) {
      await generateDataPointRequestsForPastDates({
        pastDatesInput: { pastDates },
        projectId: props.project._id,
        dataPointTypeId,
      });
    }
    resetModalData();
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'entityLocationSubcategorySummaries' });
    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getMyDatapointRequests' });
    notify({ type: 'success', text: t('Question has been successfully edited.') });
  } catch (err) {
    resetModalData();
    if (deleteDataPointRequestDelegationError.value) {
      notify({ type: 'error', text: t(deleteDataPointRequestDelegationError.value?.message) });
    } else if (addDataPointTypeOverrideError.value) {
      notify({ type: 'error', text: t(addDataPointTypeOverrideError.value?.message) });
    } else if (generateDataPointRequestsForPastDatesError.value) {
      notify({ type: 'error', text: t(generateDataPointRequestsForPastDatesError.value?.message) });
    } else if (deleteDataPointRequestsError.value) {
      notify({ type: 'error', text: t(deleteDataPointRequestsError.value?.message) });
    } else {
      notify({ type: 'error', text: t('Something went wrong, try again later :(') });
    }
  }
}

const dataEntrySelectMode = inject('dataEntrySelectMode', computed(() => false));
const dataEntrySelectedItemIds = inject('dataEntrySelectedItemIds', ref([] as string[]));
const hoveredItem = inject('hoveredItem', ref(''));

function toggleQuestionSelection() {
  const dprIndex = dataEntrySelectedItemIds.value.findIndex((id) => itemGroupDprIds.value.includes(id));
  if (dprIndex === -1) {
    dataEntrySelectedItemIds.value = dataEntrySelectedItemIds.value.concat(itemGroupDprIds.value);
  } else {
    pullAll(dataEntrySelectedItemIds.value, itemGroupDprIds.value);
  }
}

const isParentWithoutChilds = computed(() => props.itemGroup[0].dpr.childs?.length === 0);

const isSplitInputDataType = computed(
  () => [ValueDataTypeEnum.NUMERIC, ValueDataTypeEnum.NUMERIC_SPLIT, ValueDataTypeEnum.TEXT, ValueDataTypeEnum.TEXT_SPLIT]
    .includes(props.itemGroup[0]?.dpr.dataPointType.valueDataType));

async function handleResetDataPointRequest(dataPointRequestId: string) {
  try {
    await resetDataPointRequest({ dataPointRequestId }, { update: (cache) => cache.evict({ fieldName: 'getMyDataPointRequests' }) });
    notify({ type: 'success', text: t('The value has been successfully removed.') });
  } catch (err) {
    if (resetDataPointRequestError.value) {
      notify({ type: 'error', text: t(resetDataPointRequestError.value?.message) });
    }
  }
}

const delegations = computed(() => props.itemGroup[0].dpr.delegations ?? []);
</script>

<template>
  <div
    class="group/question bg-white"
    :class="{
      'border rounded-md my-4': !props.isChilds,
      'mt-0': props.isChilds,
    }"
    :data-cy="`OgQuestionList-item-${props.itemGroup[0].dpr.dataPointType.valueDataType}`"
  >
    <MlArchiveModal
      :isShown="isArchiveModalRevealed"
      :project="props.project"
      :dataPointTypeId="props.itemGroup[0].dpr.dataPointType._id"
      @cancel="isArchiveModalRevealed = false"
      @confirm="isArchiveModalRevealed = false"
    />

    <MlRemoveValueModal
      :isShown="isRemoveValueModalRevealed"
      :items="props.itemGroup"
      @cancel="isRemoveValueModalRevealed = false"
      @confirm="isRemoveValueModalRevealed = false"
    />

    <MlDelegateQuestionModal
      :isShown="isDelegateQuestionModalRevealed"
      :items="props.itemGroup"
      @cancel="isDelegateQuestionModalRevealed = false"
    />

    <OgEditQuestionModal
      v-if="isEditQuestionModalRevealed"
      :isRevealed="isEditQuestionModalRevealed"
      :text="t(editQuestionItem?.question ?? '')"
      :item="editQuestionItem"
      :loading="addDataPointTypeOverrideLoading
        || generateDataPointRequestsForPastDatesLoading
        || deleteDataPointRequestsLoading
        || deleteDataPointRequestDelegationLoading"
      :error="addDataPointTypeOverrideError"
      :isChilds="isChilds"
      :isParentWithoutChilds="isParentWithoutChilds"
      :isSplitInputDataType="isSplitInputDataType"
      :historicDprs="historicDprs"
      :dataPointTypeOverrides="dataPointTypeOverrides"
      :isEmissions="['emissions', 'scope_1_emissions', 'scope_2_emissions', 'scope_3_emissions']
        .includes(route.query.subcategory?.toString() || '')
        && [ValueDataTypeEnum.NUMERIC, ValueDataTypeEnum.NUMERIC_SPLIT].includes(props.itemGroup[0].dpr.dataPointType.valueDataType)"
      @cancel="resetModalData"
      @confirm="handleConfirm"
    />

    <TmCustomDataPointTypeModal
      v-if="isCustomDataPointTypeModalRevealed"
      :dataPointTypeId="props.itemGroup[0].dpr.dataPointType._id"
      crud="update"
      @close="isCustomDataPointTypeModalRevealed = false"
    />
    <button
      ref="questionButton"
      class="scroll-mt-14 group grid w-full grid-cols-[7.5fr_1fr_1.4fr_1.6fr_0.6fr] items-start justify-items-start gap-x-2 p-3 text-left focus:outline-none focus-visible:text-primary"
      :class="{
        'text-gray-400': isPending,
        'rounded-none': props.isChilds && props.index !== 0,
        'bg-gray-50': hoveredItem === props.itemGroup[0].dpr._id,
        'rounded-md': !props.isChilds,
      }"
      data-cy="OgQuestionList-button"
      :data-datapointtype="props.itemGroup[0].dpr.dataPointType._id"
      type="submit"
      @click.prevent.stop="toggleCollapse(props.itemGroup[0].dpr._id);"
    >
      <p class="inline-flex h-full items-start">
        <AtCheckbox
          v-if="dataEntrySelectMode"
          :id="`dataEntrySelect_${props.itemGroup[0].dpr._id}`"
          :checked="dataEntrySelectedItemIds.includes(props.itemGroup[0].dpr._id)"
          @toggleCheckbox="toggleQuestionSelection"
          @click.stop
        />
        <ChevronRightIcon
          v-show="props.isChilds && !activeCollapses[props.itemGroup[0].dpr._id] "
          class="mt-0.5 mr-1 w-5"
        />
        <ChevronDownIcon
          v-show="props.isChilds && activeCollapses[props.itemGroup[0].dpr._id]"
          class="mt-0.5 mr-1 w-5"
          :class="{ 'text-primary': isMainSelected }"
        />
        <span class="w-full">
          <span class="mr-2 whitespace-nowrap">
            <span
              class="whitespace-pre-line"
              :class="{ 'text-primary': isMainSelected }"
            >
              {{ t(props.itemGroup[0].question) }}
            </span>
          </span>
          <div
            :class="{
              block: activeCollapses[props.itemGroup[0].dpr._id],
              'hidden group-hover/question:block': !activeCollapses[props.itemGroup[0].dpr._id],
            }"
          >
            <MlStandardsBadges
              :activeReportingFramework="props.itemGroup[0].dpr.dataPointType.activeReportingFramework"
              :reportingFrameworks="props.itemGroup[0].dpr.dataPointType.reportingFrameworks"
            />
            <MlCustomBadges
              :tags="override?.tags"
            />
            <MlCustomTagsPopover
              v-if="activeCollapses[props.itemGroup[0].dpr._id]"
              v-rolefrom="UserRole.MANAGER"
              :question="props.itemGroup[0]"
              :override="override"
              :entityTags="entityTags"
              class="inline-block"
            >
              <template #popoverButton>
                <AtBadge class="bg-gray-50 text-gray-400 hover:text-primary">
                  {{ t('+add tag') }}
                </AtBadge>
              </template>
            </MlCustomTagsPopover>
          </div>
        </span>
      </p>
      <span class="justify-self-end">
        {{ getTaskCount(props.itemGroup) || '' }}
        <span v-for="delegation in delegations" :key="delegation._id">
          <AtAvatar
            v-if="(!isDelegationAssignee || isAdminOrSuperAdmin)
              && delegation?.user.email
              && !delegation.user.isExternal"
            :userName="getUserName(delegation?.user)"
            :url="delegation?.user.picture?.downloadUrl"
            class="!w-6"
          />
        </span>
      </span>
      <div class="grid gap-1 w-full justify-end">
        <AtBadge
          v-if="(!delegations[0]?.user.email
            || (isDelegationAssignee && !isAdminOrSuperAdmin))
            && !activeCollapses[props.itemGroup[0].dpr._id]"
          :type="itemGroupSorted[itemGroupSorted.length - 1].dpr.displayStatus"
        >
          {{ capitalize(itemGroupSorted[itemGroupSorted.length - 1].status) }}
        </AtBadge>
        <AtBadge
          v-else-if="delegations[0]?.user.isExternal && !activeCollapses[props.itemGroup[0].dpr._id]"
          :type="props.itemGroup[itemGroupSorted.length - 1].dpr.displayStatus"
        >
          {{ capitalize(t('Forwarded')) }}
        </AtBadge>
        <AtBadge
          v-else-if="delegations[0]?.user.email && !activeCollapses[props.itemGroup[0].dpr._id]"
          :title="t(props.itemGroup[itemGroupSorted.length - 1].dpr.displayStatus)"
          :type="props.itemGroup[itemGroupSorted.length - 1].dpr.displayStatus"
        >
          {{ capitalize(t('Assigned')) }}
        </AtBadge>
      </div>
      <span
        class="text-gray-400 text-right w-full text-sm"
        :title="itemGroupSorted[itemGroupSorted.length - 1].dueDate"
      >
        <template v-if="!isChilds">
          <span v-show="!activeCollapses[props.itemGroup[0].dpr._id]">
            {{ itemGroupSorted[itemGroupSorted.length - 1].dueDateFromNow }}
          </span>
        </template>
      </span>
      <MlMenu
        class="grid justify-self-end justify-end"
        placement="bottom-end"
        :class="{
          'hidden group-hover/question:grid': !isRemoveValueModalRevealed && !isEditQuestionModalRevealed && !isDelegateQuestionModalRevealed && !activeCollapses[props.itemGroup[0].dpr._id],
          'group-hover/question:grid': isEditQuestionModalRevealed || isDelegateQuestionModalRevealed || isRemoveValueModalRevealed,
        }"
      >
        <button
          type="button"
          class="group flex items-center px-1 pb-2"
          @click.stop
        >
          <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50 text-gray-400" />
        </button>
        <template #menuItems>
          <AtMenuItem
            v-if="!isAccepted"
            v-rolefrom="UserRole.MANAGER"
            @click.stop="openDelegateQuestionModal"
          >
            {{ t('Share') }}
          </AtMenuItem>

          <AtMenuItem
            v-if="isEditable
              && (isAdminOrSuperAdmin
                || (!isAdminOrSuperAdmin && isSplitInputDataType && (isChilds || isParentWithoutChilds))
              )"
            v-rolefrom="UserRole.MANAGER"
            @click.stop="openEditQuestionModal"
          >
            {{ t('Edit') }}
          </AtMenuItem>

          <AtMenuItem
            v-rolefrom="UserRole.ADMIN"
            @click.stop="isArchiveModalRevealed = true"
          >
            {{ t('Archive') }}
          </AtMenuItem>
          <AtMenuItem
            v-if="isCustom"
            v-rolefrom="UserRole.ADMIN"
            @click.stop="openCustomDataPointTypeModal"
          >
            {{ t('Customize') }}
          </AtMenuItem>
          <AtMenuItem
            v-if="![DataPointRequestStatusEnum.ACCEPTED, DataPointRequestStatusEnum.OPEN]
              .includes(props.itemGroup[0].dpr.status as DataPointRequestStatusEnum)"
            v-rolefrom="UserRole.CONTRIBUTOR"
            @click.stop="props.itemGroup.length === 1 ? handleResetDataPointRequest(props.itemGroup[0].dpr._id) : openRemoveValueModal()"
          >
            {{ t('Remove value') }}
          </AtMenuItem>
        </template>
      </MlMenu>
    </button>
    <Transition name="vertical-fade-slide">
      <div
        v-if="activeCollapses[props.itemGroup[0].dpr._id]"
        :class="{
          'pr-0': props.isChilds,
          'bg-gray-50': false, //hoveredItem === props.itemGroup[0].dpr._id,
        }"
      >
        <OgQuestionContent
          ref="questionContent"
          :dataPointRequests="itemGroupSorted.map(({ dpr }) => dpr)"
          :project="props.project"
          :isChilds="props.isChilds"
          :isQuestionHovered="false/*fixes rerender issue in SplitInput */"
          @closeCollapse="activeCollapses[props.itemGroup[0].dpr._id] = false"
          @updateActiveQuestion="emit('updateActiveQuestion', $event)"
        />
      </div>
    </Transition>
  </div>
</template>
