<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ChevronRightIcon from '@heroicons/vue/outline/ChevronRightIcon';
import DotsHorizontalIcon from '@heroicons/vue/outline/DotsHorizontalIcon';
import dayjs from '@/lib/dayjs/config';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useUpdateEntityLocationMutation from '@/api/mutations/EntityLocation/updateEntityLocation.mutation';
import useToggleCategoriesMutation from '@/api/mutations/EntityAssignment/toggleCategories.mutation';
import { apolloClient } from '@/api/apollo/client';
import { UserRole, type UpdateEntityLocationInput } from '@/__generated__/types';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import OgRenameCategory from './OgRenameCategory.vue';

enum TabName {
  TEMPLATE_LIBRARY = 'Template library',
  CUSTOM_CATEGORIES = 'Custom categories',
}

type TProps = {
  locationId?: string;
  locationName?: string;
  isShown: boolean;
  defaultTab?: TabName;
  projects: {
    _id: string;
    name: string;
  }[];
  categories: {
    _id: string;
    name: string;
    label: string;
    value: string;
    isCustomCategory: boolean;
    isEnabled: boolean;
    createdAt?: string;
    createdBy?: {
      firstName?: string;
      lastName?: string;
    };
    childs: {
      label: string;
      value: string;
    }[]
  }[]
}

const props = withDefaults(defineProps<TProps>(), {
  isShown: false,
  defaultTab: TabName.TEMPLATE_LIBRARY,
  locationId: '',
  locationName: '',
});

const { mutate: toggleCategories } = useToggleCategoriesMutation();
const { mutate: updateEntityLocationMutation } = useUpdateEntityLocationMutation();

const loading = ref(false);

const emit = defineEmits<{
  close: [],
  createQuestionnaire: [],
}>();

const { t } = useI18n();

const actionPanelTabs = computed(() => {
  return [
    TabName.TEMPLATE_LIBRARY,
    TabName.CUSTOM_CATEGORIES,
  ];
});

const renamingCategory = ref<string | null>(null);

const { currentUser } = useCurrentUser();

const location = computed(() => currentUser.value?.entity.locations.find((l) => l._id === props.locationId));

const activeTab = ref<TabName>(TabName.TEMPLATE_LIBRARY);
watch(() => props.defaultTab, () => {
  activeTab.value = props.defaultTab;
}, { immediate: true });

const areCategoriesAppliedOnAllProjects = ref<boolean | null | undefined>();
const areCustomCategoriesAppliedOnAllProjects = ref<boolean | null | undefined>();

const standardCategories = computed(() => props.categories.filter((c) => !c.isCustomCategory));
const customCategories = computed(() => props.categories.filter((c) => c.isCustomCategory));

const selectedStandardCategories = ref<string[]>([]);
const selectedCustomCategories = ref<string[]>([]);

const existingCategoryNames = computed(() => props.categories.map((category) => category.name));

watch([location, standardCategories, customCategories], () => {
  selectedStandardCategories.value = standardCategories.value.filter((c) => c.isEnabled).map((c) => c.value);
  selectedCustomCategories.value = customCategories.value.filter((c) => c.isEnabled).map((c) => c.value);
  areCategoriesAppliedOnAllProjects.value = location.value?.areCategoriesAppliedOnAllProjects;
  areCustomCategoriesAppliedOnAllProjects.value = location.value?.areCustomCategoriesAppliedOnAllProjects;
}, { immediate: true });

const handleToggleApplyCategoriesToAllProjects = async () => {
  if (activeTab.value === TabName.CUSTOM_CATEGORIES) {
    areCustomCategoriesAppliedOnAllProjects.value = !areCustomCategoriesAppliedOnAllProjects.value;
  } else {
    areCategoriesAppliedOnAllProjects.value = !areCategoriesAppliedOnAllProjects.value;
  }
};

const handleToggleCategory = async (categoryValue: string, isCustomCategory?: boolean) => {
  const selectedCategories = isCustomCategory
    ? selectedCustomCategories
    : selectedStandardCategories;

  const index = selectedCategories.value.indexOf(categoryValue);

  if (index >= 0) {
    selectedCategories.value.splice(index, 1);
  } else {
    selectedCategories.value.push(categoryValue);
  }
};

const handleSave = async () => {
  loading.value = true;

  const isCustomCategoriesTab = activeTab.value === TabName.CUSTOM_CATEGORIES;
  const isTemplateLibraryTab = activeTab.value === TabName.TEMPLATE_LIBRARY;
  const selectedCategories = [...selectedCustomCategories.value, ...selectedStandardCategories.value];

  const deselectedStandardCategories = props.categories
    .filter((c) => !c.isCustomCategory)
    .filter((c) => !selectedStandardCategories.value.includes(c.value))
    .map((c) => c.value);

  const deselectedCustomCategories = props.categories
    .filter((c) => c.isCustomCategory)
    .filter((c) => !selectedCustomCategories.value.includes(c.value))
    .map((c) => c.value);

  const entityLocationInput = isCustomCategoriesTab
    ? { _id: props.locationId,
      name: props.locationName,
      areCustomCategoriesAppliedOnAllProjects: areCustomCategoriesAppliedOnAllProjects.value }
    : {
      _id: props.locationId,
      name: props.locationName,
      areCategoriesAppliedOnAllProjects: areCategoriesAppliedOnAllProjects.value,
    };

  const updateCategories = async (projects: typeof props.projects, enable: string[], disable: string[]) => {
    const categoriesToggled = projects.map((project) => toggleCategories({
      locationId: project._id,
      enable,
      disable,
    }));
    await Promise.all(categoriesToggled);
  };

  const handleMutations = async (updateEntityLocationInput: UpdateEntityLocationInput) => {
    if (updateEntityLocationInput.areCustomCategoriesAppliedOnAllProjects
    || updateEntityLocationInput.areCategoriesAppliedOnAllProjects) {
      if (Array.isArray(props.projects)) {
        const otherLocations = props.projects.filter((proj) => proj._id !== location.value?._id);
        const updatedLocations = otherLocations.map((otherLocation) => updateEntityLocationMutation({
          updateEntityLocationInput: {
            _id: otherLocation._id,
            name: otherLocation.name,
            ...(updateEntityLocationInput.areCustomCategoriesAppliedOnAllProjects
              ? { areCustomCategoriesAppliedOnAllProjects: false }
              : { areCategoriesAppliedOnAllProjects: false }),
          },
        }));
        await Promise.all([
          ...updatedLocations,
          updateEntityLocationMutation({
            updateEntityLocationInput,
          })]);
        await updateCategories(
          props.projects,
          selectedCategories,
          isTemplateLibraryTab ? deselectedStandardCategories : deselectedCustomCategories);
      }
    } else {
      await updateEntityLocationMutation({
        updateEntityLocationInput,
      });
      await updateCategories(
        [{ _id: location.value?._id ?? '', name: location.value?.name ?? '' }],
        selectedCategories,
        isTemplateLibraryTab ? deselectedStandardCategories : deselectedCustomCategories);
    }
  };

  await handleMutations(entityLocationInput);

  apolloClient.cache.evict({ fieldName: 'entityLocationSummaries' });
  apolloClient.cache.evict({ fieldName: 'getAllCategoriesForEntity' });

  loading.value = false;
};

function handleCreateQuestionnaireClick() {
  emit('createQuestionnaire');
}

function handleRenameCategoryClick(id: string) {
  renamingCategory.value = id;
}

function handleRenameCategoryClose() {
  renamingCategory.value = null;
}
</script>

<template>
  <MlDialog
    :isOpen="props.isShown"
    @close="emit('close')"
  >
    <div class="py-2 px-6 text-gray-700">
      {{ t('Manage categories') }}
    </div>

    <MlTabs
      :modelValue="activeTab"
      :tabs="actionPanelTabs"
      class="mb-1 ml-6"
      isEdgeToEdge
      @update:modelValue="activeTab = $event"
    />
    <div class="mx-8">
      <p class="mt-3 mb-5 text-sm text-gray-500">
        {{ t('Add or remove categories for which your team enters and reports data on.') }}
      </p>
      <div v-if="activeTab === TabName.TEMPLATE_LIBRARY">
        <AtCheckbox
          v-if="categories.filter(c => !c.isCustomCategory).length > 0"
          :label="t('Apply to all projects in this workspace')"
          :checked="areCategoriesAppliedOnAllProjects || false"
          @toggleCheckbox="handleToggleApplyCategoriesToAllProjects"
        />
        <div class="mt-4 ml-3">
          <AtCheckbox
            v-for="category in categories.filter(c => !c.isCustomCategory)"
            :key="category.value"
            class="mb-1 mr-5 rounded px-1"
            :checked="selectedStandardCategories.find((c) => c === category.value) != null"
            :label="category.label"
            @toggleCheckbox="() => handleToggleCategory(category.value)"
          />
        </div>
      </div>
      <div v-if="activeTab === TabName.CUSTOM_CATEGORIES">
        <AtCheckbox
          v-if="categories.filter(c => c.isCustomCategory).length > 0"
          key="custom-all"
          :checked="areCustomCategoriesAppliedOnAllProjects || false"
          :label="t('Apply to all projects in this workspace')"
          @toggleCheckbox="handleToggleApplyCategoriesToAllProjects"
        />
        <div class="mt-4 ml-3">
          <div
            v-for="category in categories.filter((c) => c.isCustomCategory)"
            :key="category.value"
            class="mb-2"
          >
            <template v-if="renamingCategory !== category._id">
              <div class="flex items-center space-x-1">
                <AtCheckbox
                  class="rounded px-1"
                  :checked="selectedCustomCategories.find((c) => c === category.value) != null"
                  :label="category.label"
                  @toggleCheckbox="handleToggleCategory(category.value, true)"
                />
                <MlMenu>
                  <button
                    v-rolefrom="UserRole.ADMIN"
                    type="button"
                    class="group flex items-center"
                  >
                    <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
                  </button>
                  <template #menuItems>
                    <AtMenuItem
                      class="justify-center"
                      @click="handleRenameCategoryClick(category._id)"
                    >
                      {{ t('Rename') }}
                    </AtMenuItem>
                  </template>
                </MlMenu>
              </div>
              <div class="pl-9 text-gray-500 text-sm">
                {{ t('Created on') }} {{ dayjs(category.createdAt).format('DD.MM.YYYY') }} {{ t('by') }}
                {{ category.createdBy?.firstName }}
                {{ category.createdBy?.lastName }}
              </div>
            </template>
            <OgRenameCategory
              v-else
              :category="category"
              :existingCategoryNames="existingCategoryNames"
              @close="handleRenameCategoryClose"
            />
          </div>
          <div
            class="rounded-md border p-3 flex items-center space-x-4 cursor-pointer mt-6"
            :class="'border-gray-400'"
            @click="handleCreateQuestionnaireClick"
          >
            <div class="flex-1">
              <p class="text-sm mb-1">
                {{ t('Custom questionnaire') }}
              </p>
              <p class="text-xs">
                {{ t('Upload your own questionnaire and add or additional questions') }}
              </p>
            </div>
            <div class="flex-0">
              <ChevronRightIcon class="text-gray-600 h-5 w-5" />
            </div>
          </div>
        </div>
        <div class="mt-4 ml-3" />
      </div>
      <div
        class="modal-action flex items-start align-bottom "
      >
        <AtButton
          :loading="loading"
          @click="handleSave"
        >
          {{ t('Save') }}
        </AtButton>
      </div>
    </div>
  </MlDialog>
</template>
