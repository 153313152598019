<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { PlusIcon, CheckIcon } from '@heroicons/vue/solid';
import { computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import sortBy from 'lodash/sortBy';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { i18n } from '@/lib/i18n';
import useSwitchActiveEntity from '@/utils/composables/useSwitchActiveEntity';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlMenu, { AtMenuItem } from '@/components/molecules/MlMenu/MlMenu.vue';
import useCreateEntityMutation from '@/api/mutations/createEntity.mutation';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useAddEntityToUsersMutation from '@/api/mutations/User/addEntityToUsers.mutation';
import { UserLanguageEnum, type TmSettingsEntitiesQuery, UserRole } from '@/__generated__/types';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import TM_SETTINGS_ENTITIES_QUERY from '@/components/pages/PgAdmin/Settings/TmSettingsEntities.query';
import OgCreateEntityModal from '../OgCreateEntityModal/OgCreateEntityModal.vue';
import OgWorkspacesRestrictionsModal from '../OgRestrictionsModal/OgWorkspacesRestrictionsModal.vue';

const { t } = useI18n();

const route = useRoute();
const { switchActiveEntity } = useSwitchActiveEntity();
const isAdminPath = computed(() => route.path.includes('/admin/'));
const isModulesPath = computed(() => route.path.includes('/modules'));
const showRestrictionsModal = ref(false);

const {
  refetch: refetchCurrentUser,
  updateCurrentUser,
  currentUser,
  currentUserPictureUrl,
  currentUserName,
  isAdminOrSuperAdmin,
  activeEntity,
} = useCurrentUser();

const canAccessAdminWorkspace = computed(() => isAdminOrSuperAdmin || currentUser.value?.role === UserRole.AUDITOR);

const { mutate: createEntity, error: createEntityError, loading: createEntityLoading } = useCreateEntityMutation();
const {
  mutate: addEntityToUsersMutation,
  error: addEntityToUsersError,
  loading: addEntityToUsersLoading,
} = useAddEntityToUsersMutation();

const triggerSwitchTranslation = (selectedLocale: string) => {
  updateCurrentUser({ language: selectedLocale.toUpperCase() as UserLanguageEnum });
};

const {
  restrictions,
  loading: loadingRestrictions,
} = useRestrictions();

const { result: entitiesResult } = useQuery<TmSettingsEntitiesQuery>(TM_SETTINGS_ENTITIES_QUERY);
const entities = computed(() => entitiesResult.value?.getCurrentUserEntities ?? []);

const isUnique = (name: string) => !currentUser.value?.entities.some((entity) => entity.name === name);

const createEntityName = ref('');
const isRevealed = ref(false);

function resetModalData() {
  isRevealed.value = false;
  createEntityName.value = '';
}

function handleCreateEntityModal() {
  if (restrictions.value
   && restrictions.value?.numberOfWorkspaces > 0
   && entities.value.length >= restrictions.value?.numberOfWorkspaces
   && entities.value.length > 0) {
    showRestrictionsModal.value = true;
    return;
  }
  isRevealed.value = true;
}

const v = useVuelidate({
  createEntityName: {
    unique: helpers.withMessage(t('A project with this name already exists.'), isUnique),
  },
}, { createEntityName });

const sortedEntities = computed(() => {
  return sortBy([...currentUser.value?.entities ?? []], [(entity) => entity._id !== activeEntity.value?._id]);
});

async function handleCreateEntity(usersIds?: string[]) {
  try {
    const newEntity = await createEntity({ entityInput: { name: createEntityName.value } });
    const entityId = newEntity?.data?.createEntity._id;
    if (usersIds && entityId) await addEntityToUsersMutation({ usersIds, entityId });

    resetModalData();
    refetchCurrentUser();
    notify({ type: 'success', text: t('The workspace {workspaceName} has been created.', { workspaceName: newEntity?.data?.createEntity.name }) });
  } catch (err) {
    resetModalData();
    if (createEntityError.value) notify({ type: 'error', text: t(createEntityError.value?.message) });
    if (addEntityToUsersError.value) notify({ type: 'error', text: t(addEntityToUsersError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const isMenuOpen = ref(false);

defineOptions({ inheritAttrs: false });

</script>

<template>
  <MlMenu
    v-model:shown="isMenuOpen"
    :triggers="['click']"
    placement="top-end"
    v-bind="$attrs"
  >
    <button
      class="flex items-center relative h-9"
      data-cy="MenuButtonProfile"
    >
      <AtAvatar
        class="!h-8 !w-8"
        :url="currentUserPictureUrl"
        :userName="currentUserName"
      />
      <slot />
    </button>
    <template #menuItems>
      <template v-if="currentUser?.entities.length">
        <AtMenuItem
          v-for="entity in sortedEntities"
          :key="entity._id"
          data-cy="MenuItemEntity"
          class="justify-between border-none"
          :class=" { 'text-primary': activeEntity?._id === entity._id && !isAdminPath && !isModulesPath }"
          @click.stop="switchActiveEntity(entity._id)"
        >
          {{ entity.name }}
          <CheckIcon
            v-if="activeEntity?._id === entity._id && !isAdminPath && !isModulesPath"
            class="h-5 w-5"
          />
        </AtMenuItem>
        <AtMenuItem
          v-if="isAdminOrSuperAdmin"
          class="border-none text-sm"
          :class="{ 'text-gray-400 cursor-default': loadingRestrictions }"
          data-cy="addNewEntity"
          :disabled="loadingRestrictions"
          @click.stop="handleCreateEntityModal"
        >
          <PlusIcon class="mr-2 h-4 w-4" />
          {{ t('Add new workspace') }}
        </AtMenuItem>
      </template>

      <router-link v-if="canAccessAdminWorkspace" :to="{ name: 'adminHome' }" class="block">
        <AtMenuItem
          class="text-left !rounded-none"
        >
          <div
            class="flex justify-between w-full"
            :class="{
              'text-blue-600': isAdminPath,
            }"
          >
            <div class="">
              {{ t('Admin workspace') }}
            </div>
            <CheckIcon
              v-if="isAdminPath"
              class="h-5 w-5 text-blue-600"
            />
          </div>
        </AtMenuItem>
      </router-link>

      <router-link :to="{ name: 'modules' }" class="block">
        <AtMenuItem
          class="text-left !rounded-none"
        >
          <div
            class="flex justify-between w-full"
            :class="{
              'text-blue-600': isModulesPath,
            }"
          >
            <div class="">
              {{ t('Modules') }}
            </div>
            <CheckIcon
              v-if="isModulesPath"
              class="h-5 w-5 text-blue-600"
            />
          </div>
        </AtMenuItem>
      </router-link>

      <AtMenuItem
        noClose
        class="cursor-default"
      >
        <div class="flex items-center">
          <span>{{ t('Language:') }}&nbsp;</span>
          <ul class="ml-2 flex h-full justify-center gap-2">
            <li
              v-for="availableLocale in i18n.global.availableLocales"
              :key="availableLocale"
              class="flex h-full cursor-pointer items-center uppercase hover:text-primary"
              @click="triggerSwitchTranslation(availableLocale)"
            >
              {{ availableLocale }}
            </li>
          </ul>
        </div>
      </AtMenuItem>

      <router-link :to="{ name: 'logout' }">
        <AtMenuItem
          data-cy="MenuItemLogout"
          class="text-left !rounded-none"
        >
          {{ t('Sign out') }}
        </AtMenuItem>
      </router-link>
    </template>
  </MlMenu>
  <OgCreateEntityModal
    v-if="isRevealed && isAdminOrSuperAdmin"
    :loading="createEntityLoading || addEntityToUsersLoading"
    :error="createEntityError"
    :isRevealed="isRevealed"
    :confirmDisabled="v.$invalid || !createEntityName"
    data-cy="OgCreateEntityModal"
    @confirm="handleCreateEntity"
    @cancel="resetModalData"
  >
    <AtInput
      v-model="createEntityName"
      :label="t('Name')"
      wrapperClass="my-4 w-96"
      data-cy="AtInputCreateEntityName"
      :errors="v.createEntityName.$silentErrors"
    />
  </OgCreateEntityModal>
  <OgWorkspacesRestrictionsModal
    :showModal="showRestrictionsModal"
    @closeModal="showRestrictionsModal = false"
  />
</template>
