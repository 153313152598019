import { computed, inject, reactive } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import type { PgCarbonFootprintViewQuery, PgCarbonFootprintViewQueryVariables } from '@/__generated__/types';
import {
  type DateRange,
  formatDateRangeForBE,
  useReportingPeriod,
} from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import type { MultiLevelSelectItem, Project } from '../types';
import { getProjectsTrees, type ProjectsTree } from '../services/projectsTree';
import PG_CARBON_FOOTPRINT_VIEW_QUERY from './PgCarbonFootprintView.query';

export function usePgCarbonFootprintView() {
  const { t } = useI18n();

  const adminMode = inject('adminMode', computed(() => false));

  const {
    dateRange,
  } = useReportingPeriod();
  const filters: {
    workspaces: string[],
    project: string,
    scope: string,
    emissionSource: string,
    dateRange: DateRange,
  } = reactive({
    workspaces: [],
    project: '',
    scope: '',
    emissionSource: '',
    dateRange,
  });

  const variables = computed<PgCarbonFootprintViewQueryVariables>(() => ({
    ...formatDateRangeForBE(filters.dateRange.from, filters.dateRange.to),
    adminMode: adminMode.value,
    projectId: filters.project,
    workspaceIds: filters.workspaces,
  }));
  const {
    result,
    loading,
  } = useQuery<PgCarbonFootprintViewQuery, PgCarbonFootprintViewQueryVariables>(
    PG_CARBON_FOOTPRINT_VIEW_QUERY,
    variables,
    { fetchPolicy: 'cache-and-network' },
  );

  const allDataPoints = computed(() => {
    const dataPoints = (adminMode.value
      ? result?.value?.getCarbonFootprintDataPointsByProjectAndDateRangeAdmin
      : result?.value?.getCarbonFootprintDataPointsByProjectAndDateRange
    ) ?? [];

    return dataPoints;
  });

  const filteredDataPoints = computed(() => {
    return allDataPoints.value.filter((dataPoint) => {
      if (filters.emissionSource) {
        return dataPoint.dataPointType.emissionSubcategory === filters.emissionSource;
      }

      if (filters.scope) {
        return dataPoint.dataPointType.emissionType === filters.scope || dataPoint.dataPointType.emissionCategory === filters.scope;
      }

      return true;
    });
  });

  // If in admin workspace, show entities, otherwise show locations.
  const projects = computed<Project[]>(() => {
    if (adminMode.value) {
      return (result.value?.getCurrentUserEntities || []).map((entity) => ({
        _id: entity._id,
        name: entity.name,
      } as Project));
    }

    return (result.value?.entityLocationSummaries || []).map((location) => ({
      _id: location._id,
      name: location.name,
      parent: location.parent,
      hasParentInfo: location.hasParentInfo,
    } as Project));
  });

  const noData = computed(() => allDataPoints.value.length === 0 && !loading.value);

  const projectsOptions = computed(
    () => {
      const treeToSelect = (trees: ProjectsTree[]) => {
        return trees.map((tree) => {
          const selectItem: MultiLevelSelectItem = {
            id: tree.id,
            title: tree.name,
          };

          if (tree.children.length > 0) {
            selectItem.children = treeToSelect(tree.children);
          }

          return selectItem;
        });
      };
      const projectsTrees = getProjectsTrees(projects.value);

      return treeToSelect(projectsTrees);
    },
  );
  const workspaceOptions = computed<Record<string, string>>(() => {
    if (result.value?.getCurrentUserEntities) {
      return result.value.getCurrentUserEntities.reduce((acc, { _id, name }) => ({
        ...acc,
        [_id]: name,
      }), {});
    }

    return {};
  });

  const allScopesAndEmissionSources = computed(() => allDataPoints
    .value
    .filter(({ dataPointType }) => dataPointType.emissionType && dataPointType.emissionCategory && dataPointType.emissionSubcategory)
    .map(({ dataPointType }) => ({
      scope: dataPointType.emissionType!,
      scopeSublevel: dataPointType.emissionCategory!,
      emissionSource: dataPointType.emissionSubcategory!,
    })));
  const scopeOptions = computed<MultiLevelSelectItem[]>(() => {
    const grouped = mapValues(
      groupBy(allScopesAndEmissionSources.value, (item) => item.scope),
      (items) => groupBy(items, (item) => item.scopeSublevel),
    );

    return Object.entries(grouped).map(([scope, scopeSublevels]) => {
      return {
        id: scope,
        title: t(scope),
        children: Object.keys(scopeSublevels).map((scopeSublevel) => ({
          id: scopeSublevel,
          title: t(scopeSublevel),
        })),
      };
    });
  });
  const emissionSourceOptions = computed<Record<string, string>>(() => {
    return (filters.scope
      ? allScopesAndEmissionSources.value.filter((item) => item.scope === filters.scope || item.scopeSublevel === filters.scope)
      : allScopesAndEmissionSources.value
    )
      .reduce((values, item) => ({
        ...values,
        [item.emissionSource]: t(item.emissionSource),
      }), {});
  });

  return {
    isLoading: loading,
    filters,
    filtersData: {
      projectsOptions,
      workspaceOptions,
      scopeOptions,
      emissionSourceOptions,
    },
    noData,
    projects,
    dataPoints: filteredDataPoints,
  };
}
