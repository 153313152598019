import { computed, reactive } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { type PgQualitativeViewQuery, type PgQualitativeViewQueryVariables, ReportingFrameworkEnum } from '@/__generated__/types';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import {
  type DateRange,
  formatDateRangeForBE,
  useReportingPeriod,
} from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import type { DataPointQualitativeAdmin, Project, DataPointSummaryAdmin } from '../types';
import { getPerDataPointType } from '../services/prepareQualitativeData';
import PG_QUALITATIVE_VIEW_QUERY from './PgQualitativeView.query';

export function usePgQualitativeView() {
  const translateCategory = useCategoryTranslate();

  const {
    dateRange,
  } = useReportingPeriod();
  const filters: {
    workspaces: string[],
    standards: string[],
    categories: string[],
    subcategories: string[],
    dateRange: DateRange,
  } = reactive({
    workspaces: [],
    standards: [],
    categories: [],
    subcategories: [],
    dateRange,
  });

  const variables = computed<PgQualitativeViewQueryVariables>(() => ({
    ...formatDateRangeForBE(filters.dateRange.from, filters.dateRange.to),
    workspaceIds: filters.workspaces,
  }));
  const { result, loading } = useQuery<PgQualitativeViewQuery, PgQualitativeViewQueryVariables>(
    PG_QUALITATIVE_VIEW_QUERY,
    variables,
    { fetchPolicy: 'cache-and-network' },
  );

  const allDataPoints = computed(() => {
    const dataPoints = result?.value?.getQualitativeDataPointsByProjectAndDateRangeAdmin ?? [];
    return dataPoints as (DataPointQualitativeAdmin | DataPointSummaryAdmin)[];
  });

  const summaryDataPoints = computed(() => result?.value?.getSummaryDataPointsByProjectAndDateRangeAdmin ?? []);

  const filteredDataPoints = computed(() => {
    return allDataPoints.value
      .filter((dataPoint) => {
        if (filters.standards.length === 0) {
          return true;
        }

        return dataPoint.dataPointType.activeReportingFramework.groups.some((group) => {
          return filters.standards.some((standard) => {
            const isMatched = !!group.match(`^${standard}-`);
            const isDNKMatched = !!group.match(`^${standard}, Aspect`);
            const isSomeESRSMatched = !!group.match(`^${standard},`); // for some ESRS cases like "ESRS 2, para. 5-a"

            return isMatched || isDNKMatched || isSomeESRSMatched;
          });
        });
      })
      .filter((dataPoint) => {
        if (filters.subcategories.length === 0) {
          return true;
        }

        return filters.subcategories.some((subcategory) => {
          return subcategory === dataPoint.dataPointType.activeReportingFramework.subcategory;
        });
      })
      .filter((dataPoint) => {
        if (filters.categories.length === 0) {
          return true;
        }

        return filters.categories.some((category) => {
          return category === dataPoint.dataPointType.activeReportingFramework.category;
        });
      });
  });

  const projects = computed<Project[]>(() => {
    return (result.value?.entitySummaries || []).map((location) => ({
      _id: location._id,
      name: location.name,
      entityId: location.entityId,
      entityName: location.entityName,
      parent: location.parent,
      hasParentInfo: location.hasParentInfo,
    } as Project));
  });

  const dataPointTypesData = computed(() => getPerDataPointType(
    filteredDataPoints.value,
    projects.value,
    summaryDataPoints.value,
  ));

  const noData = computed(() => allDataPoints.value.length === 0 && !loading.value);

  const workspaceOptions = computed<Record<string, string>>(() => {
    if (result.value?.getCurrentUserEntities) {
      return result.value.getCurrentUserEntities.reduce((acc, { _id, name }) => ({
        ...acc,
        [_id]: name,
      }), {});
    }

    return {};
  });
  const categoriesOptions = computed<Record<string, string>>(() => {
    if (result.value?.getAvailableSubcategories) {
      return result.value.getAvailableSubcategories
        .reduce((acc, { category }) => ({
          ...acc,
          [category]: translateCategory(category),
        }), {});
    }

    return {};
  });
  const subcategoriesOptions = computed<Record<string, string>>(() => {
    if (result.value?.getAvailableSubcategories) {
      return result.value.getAvailableSubcategories
        .reduce((acc, { category, subcategory }) => ({
          ...acc,
          [subcategory]: translateCategory(category, subcategory),
        }), {});
    }

    return {};
  });
  const standardsOptions = computed<Record<string, string>>(() => {
    const options: Record<string, string> = {};

    filteredDataPoints.value
      .filter((dataPoint) => {
        if (dataPoint.dataPointType.activeReportingFramework.groups.length > 0) {
          return true;
        }

        return dataPoint.dataPointType.activeReportingFramework.framework === ReportingFrameworkEnum.CUSTOM;
      })
      .forEach((dataPoint) => {
        dataPoint.dataPointType.activeReportingFramework.groups.forEach((group) => {
          const match = group.match(/^([0-9]+)/);
          const matchSomeESRS = group.match(/^(E\d+|ESRS \d+|S\d+)/);
          const name = match || matchSomeESRS;

          if (name && name[0]) {
            options[name[0]] = `${dataPoint.dataPointType.activeReportingFramework.framework}-${name[0]}`;
          }
        });
      });

    return options;
  });

  return {
    isLoading: loading,
    filters,
    filtersData: {
      workspaceOptions,
      categoriesOptions,
      subcategoriesOptions,
      standardsOptions,
    },
    noData,
    dataPointTypesData,
  };
}
